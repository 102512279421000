import React from 'react'
import PropTypes from 'prop-types'
import MiniTeamMember from './MiniTeamMember'
import PortraitTeamMember from './PortraitTeamMember'
import DebugTeamMember from './DebugTeamMember'
import FeaturedTeamMember from './FeaturedTeamMember'

function TeamMemberCard ({ variant, ...rest }) {
  switch (variant) {
    case 'debug':
      return (<DebugTeamMember {...rest} />)
    case 'featured':
      return (<FeaturedTeamMember {...rest} />)
    case 'portrait':
      return (<PortraitTeamMember {...rest} />)
    case 'mini':
      return (<MiniTeamMember {...rest} />)
    default:
      return (<MiniTeamMember {...rest} />)
  }
}

TeamMemberCard.propTypes = {
  variant: PropTypes.oneOf(['mini', 'portrait'])
}

TeamMemberCard.defaultProps = {
  variant: 'mini'
}

export default TeamMemberCard
