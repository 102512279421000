/* eslint-disable react/jsx-boolean-value */
import React, { useCallback, useEffect, useMemo, useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isBetween from 'dayjs/plugin/isBetween'
import utc from 'dayjs/plugin/utc'
import { makeStyles } from '@material-ui/core/styles'
import { ResponsiveLine } from '@nivo/line'
import clsx from 'clsx'
import { area } from 'd3-shape'
import noop from 'lodash/lodash'
import numeral from 'numeral'
import { numFormatter } from '../../utils'
import entryType, { entryTypeIdentifier, entryTypePropType } from '../organisms/WealthJourney/v2/entryType'
import StyledLines from '../nivo/StyledLines'
import { mapThemingColor } from '../organisms/WealthJourney/shared/mapThemingColor'

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(isBetween)
dayjs.extend(utc)

const customTooltipStyle = {
  container: {
    background: 'white',
    color: 'inherit',
    fontSize: 'inherit',
    borderRadius: '2px',
    boxShadow: 'rgb(0 0 0 / 25%) 0px 1px 2px',
    padding: '5px 9px'
  },
  content: {
    whiteSpace: 'pre',
    display: 'flex',
    alignItems: 'center'
  },
  span: ({ color }) => ({
    display: 'block',
    width: '12px',
    height: '12px',
    background: color,
    marginRight: '7px'
  })
}

const useAreaHighlightLayer = (activeDatePeriod, hideLine) => {
  const [start, end] = useMemo(() => [
    dayjs.utc(`${activeDatePeriod.value}-01-01T00:00:00.000Z`).startOf('year'),
    dayjs.utc(`${activeDatePeriod.value + 1}-01-01T00:00:00.000Z`).startOf('year')
  ], [activeDatePeriod.value])
  return useCallback(({ series, xScale, innerHeight }) => {
    const areaGenerator = area()
      .x(d => xScale(d.data.x))
      .y0(() => innerHeight)
      .y1(() => 0)
      .defined(d => {
        const isAfter = dayjs.utc(d.data.x).isSameOrAfter(start, 'month')
        const isBefore = dayjs.utc(d.data.x).isSameOrBefore(end, 'month')

        return isAfter && isBefore
      })
    if (hideLine) return null
    return (
      <path
        d={areaGenerator(series[0].data)}
        fill='#5B91F4'
        fillOpacity={0.1}
      />
    )
  }, [start, end, hideLine])
}

const useMilestoneStyles = makeStyles((theme) => ({
  milestone: {
    fill: ({ theming }) => {
      return mapThemingColor(theming?.chart?.milestoneColor, theme, '#5B91F4')
    },
    stroke: 'white',
    strokeWidth: '.2rem',
    '&:hover': {
      stroke: 'black'
    },
    transition: 'all 200ms ease-in-out'
  },
  milestoneSelected: {
    fill: 'white',
    stroke: ({ theming }) => mapThemingColor(theming?.chart?.milestoneColor, theme, '#5B91F4')
  },
  meeting: {
    fill: ({ theming }) => mapThemingColor(theming?.chart?.meetingColor, theme, theme.palette.cloudBurst),
    stroke: 'white',
    strokeWidth: '.2rem',
    '&:hover': {
      stroke: ({ theming }) => mapThemingColor(theming?.chart?.meetingColor, theme, theme.palette.cloudBurst)
    },
    transition: 'all 200ms ease-in-out'
  },
  meetingSelected: {
    fill: 'white',
    stroke: theme.palette.cloudBurst
  },
  hoverRect: {
    fill: 'transparent',
    stroke: 'transparent',
    strokeWidth: '2'
  },
  futureMilestone: {
    fill: ({ theming }) => mapThemingColor(theming?.chart?.futureMilestoneColor, theme, '#FFE082'),
    stroke: 'white',
    strokeWidth: '.2rem',
    '&:hover': {
      stroke: 'black'
    },
    transition: 'all 200ms ease-in-out'
  },
  futureMilestoneSelected: {
    fill: 'white',
    stroke: ({ theming }) => mapThemingColor(theming?.chart?.futureMilestoneColor, theme, '#FFE082')
  },
  titleBackdrop: {
    fill: 'white'
  },
  title: {
    opacity: 0,
    transition: 'all 200ms ease-in-out'
  },
  titleSelected: {
    opacity: 1
  },
  line: {
    transition: 'all 200ms ease-in-out'
  }
}))

const isMilestone = (entry) => entry.entryTypeId === entryTypeIdentifier.MILESTONES
const isMeeting = (entry) => entry.entryTypeId === entryTypeIdentifier.MEETINGS

const useMilestoneMarkers = ({
  entries,
  selectedEntry,
  selectedEntryType,
  onEntrySelected,
  filterEntryTypesOnChart,
  includeMeetings,
  includeMilestones,
  today,
  hideLine,
  theming
}) => {
  const [circleHovered, setCircleHovered] = useState(null)
  const onMouseEnter = useCallback((id) => setCircleHovered(id), [setCircleHovered])
  const onMouseLeave = useCallback(() => setCircleHovered(null), [setCircleHovered])
  const classes = useMilestoneStyles({ theming })
  const filteredEntries = useMemo(() => {
    let defaultedEntries = entries || []
    if (!includeMeetings) {
      defaultedEntries = defaultedEntries.filter(x => !isMeeting(x))
    }
    if (!includeMilestones) {
      defaultedEntries = defaultedEntries.filter(x => !isMilestone(x))
    }
    if (!filterEntryTypesOnChart) {
      return defaultedEntries.filter(x => isMilestone(x) || isMeeting(x))
    }
    if (selectedEntryType === entryType.MILESTONES) {
      return defaultedEntries.filter(isMilestone)
    }
    if (selectedEntryType === entryType.MEETINGS) {
      return defaultedEntries.filter(isMeeting)
    }

    return []
  }, [selectedEntryType, entries, filterEntryTypesOnChart, includeMeetings, includeMilestones])

  return useCallback(({ xScale, innerHeight: originalInnerHeight }) => {
    const innerHeight = hideLine ? originalInnerHeight / 2 : originalInnerHeight
    const circles = filteredEntries.map(entry => {
      const isSelected = entry.entryId === selectedEntry?.entryId
      const showDetails = isSelected || circleHovered === entry.entryId
      const isInFuture = dayjs.utc(entry.entryDate).isAfter(today, 'month')
      const x = xScale(dayjs(entry.entryDate))
      const isMeeting = entry.entryTypeId === entryTypeIdentifier.MEETINGS
      const title = entry?.entryJson?.title

      return {
        metadata: {
          entry
        },
        key: entry.entryId,
        x: x,
        title,
        isSelected,
        innerHeight,
        circle: {
          className: clsx({
            [isMeeting ? classes.meeting : classes.milestone]: !isInFuture,
            [classes.futureMilestone]: isInFuture,
            [isMeeting ? classes.meetingSelected : classes.milestoneSelected]: !isInFuture && showDetails,
            [classes.futureMilestoneSelected]: isInFuture && showDetails
          }),
          cx: x,
          cy: showDetails ? innerHeight - 20 : innerHeight,
          r: showDetails ? 10 : 8
        },
        text: {
          className: clsx(classes.title, {
            [classes.titleSelected]: showDetails
          }),
          x: x + 15,
          y: innerHeight - 17
        },
        textBackdrop: {
          className: clsx(classes.titleBackdrop, {
            [classes.titleBackdrop]: showDetails
          }),
          x: x - 20,
          y: innerHeight - 40,
          width: 200,
          height: 40
        },
        line: {
          className: classes.line,
          x1: x,
          x2: x,
          y1: showDetails ? innerHeight - 20 : innerHeight,
          y2: innerHeight,
          stroke: 'black',
          strokeWidth: 2
        },
        rect: {
          x: x - 10,
          y: innerHeight - 30,
          width: 20,
          height: 40,
          className: classes.hoverRect
        }
      }
    })

    return (
      <>
        {circles.map(c => (
          <Fragment key={`ci_${c.key}`}>
            <line
              {...c.line}
              onMouseEnter={() => onMouseEnter(c.key)}
              onMouseLeave={onMouseLeave}
            />
            <text {...c.text}>{c.title}</text>
            <rect
              onMouseEnter={() => onMouseEnter(c.key)}
              onMouseLeave={onMouseLeave}
              {...c.rect}
            />
            <circle
              {...c.circle}
              onClick={() => onEntrySelected(c.metadata.entry)}
              onMouseEnter={() => onMouseEnter(c.key)}
              onMouseLeave={onMouseLeave}
            />
          </Fragment>
        ))}
      </>
    )
  }, [
    filteredEntries,
    selectedEntry,
    onEntrySelected,
    classes,
    circleHovered,
    today,
    onMouseLeave,
    onMouseEnter,
    hideLine
  ])
}

const useChartConfig = (yearsInRange, hideLine, ticks) => {
  return useMemo(() => ({
    fontSize: 15,
    animate: false,
    margin: { top: 30, right: 45, bottom: 30, left: 50 },
    xScale: {
      type: 'time',
      format: '%Y/%m/%d',
      precision: 'day',
      useUTC: false // the dates are already in utc, so do not adjust them like they are not
    },
    xFormat: 'time: %b %Y',
    yFormat: ' >-,.0d',
    tooltip: (input) => {
      const {
        data: { xFormatted, y },
        color,
        serieId
      } = input.point
      const label = `${serieId} ${xFormatted}, $${numFormatter(y)}`
      return (
        <div style={customTooltipStyle.container}>
          <div style={customTooltipStyle.content}>
            <span style={customTooltipStyle.span({ color })} />
            <span>{label}</span>
          </div>
        </div>
      )
    },
    ...(!hideLine ? {
      axisLeft: {
        tickPadding: 10,
        tickValues: ticks,
        format: value => numeral(value).format('0.[0]a').toUpperCase()
      }
    } : { axisLeft: null }),
    axisBottom: {
      visible: true,
      format: (value) => {
        return dayjs.utc(value).format('YYYY')
      },
      legend: '',
      legendOffset: 0,
      tickValues: yearsInRange,
      orient: 'bottom'
    },
    curve: 'basis',
    colors: (d) => d.color,
    lineWidth: 3,
    enableGridX: false,
    enableGridY: true,
    pointSize: 0,
    useMesh: true,
    motionStiffness: 175,
    motionDamping: 25
  }), [yearsInRange, hideLine, ticks])
}

const WealthJourneyLine = ({
  data: dataSetBase,
  activeDatePeriod,
  ticks,
  entries,
  selectedEntry,
  onEntrySelected,
  yearsInRange,
  selectedEntryType,
  filterEntryTypesOnChart,
  includeMeetings,
  includeMilestones,
  hideMarketValue,
  hideActiveDatePeriodMarker,
  theming
}) => {
  const [dataSetNew, setDataSetNew] = useState(false)

  useEffect(() => {
    // omit meta property
    let filteredData = Object.entries(dataSetBase).filter(x => x[0] !== 'meta').map(x => x[1])
    if (hideMarketValue && filteredData) {
      filteredData = filteredData.map(dataset => {
        if (dataset.data) {
          const newData = dataset.data.map((item) => ({ x: item.x, y: null }))
          return {
            ...dataset,
            data: newData
          }
        }
        return dataset
      })
    }
    setDataSetNew(filteredData)
  }, [dataSetBase, hideMarketValue])

  const today = useMemo(() => {
    return dayjs.utc(dataSetBase?.meta?.endDate).startOf('month')
  }, [dataSetBase])

  const areaHighlightLayer = useAreaHighlightLayer(activeDatePeriod, hideMarketValue)

  const milestoneMarkers = useMilestoneMarkers({
    entries,
    selectedEntry,
    onEntrySelected,
    selectedEntryType,
    filterEntryTypesOnChart,
    includeMeetings,
    includeMilestones,
    today,
    hideLine: hideMarketValue,
    theming
  })

  const maxDataPointValue = useMemo(() => {
    const dataPoints = Object.values(dataSetBase)
      .filter(Boolean)
      .reduce((acc, { data } = {}) => (data ? [...acc, ...data] : acc), [])
      .reduce((acc, { y }) => (y ? [...acc, y] : acc), [])
    return Math.max(...dataPoints) * (hideMarketValue ? 0 : 1.2)
  }, [dataSetBase, hideMarketValue])

  const chartConfig = useChartConfig(yearsInRange, hideMarketValue, ticks)

  const markers = useMemo(() => {
    if (hideMarketValue) return []

    const defaultMarkers = [{
      axis: 'x',
      value: today,
      lineStyle: { stroke: '#21294599', strokeWidth: 2, strokeDasharray: '5,5' },
      textStyle: { fontFamily: 'GothamPro', fontWeight: 700 }
    },
    {
      axis: 'x',
      value: dayjs.utc(`${activeDatePeriod.value}-12-31`),
      textStyle: { fontFamily: 'GothamPro' },
      lineStyle: { stroke: '#5B91F466', strokeWidth: 2 }
    }]

    if (!hideActiveDatePeriodMarker) {
      const activeDatePeriodMarker = {
        axis: 'x',
        value: dayjs.utc(`${activeDatePeriod.value}-01-01`),
        lineStyle: { stroke: '#5B91F466', strokeWidth: 2 },
        textStyle: { fontFamily: 'GothamPro', fontWeight: 700, alignSelf: 'left' },
        legend: activeDatePeriod.value,
        legendOffsetX: -40,
        legendOrientation: 'horizontal'
      }
      defaultMarkers.push(activeDatePeriodMarker)
    }
    return defaultMarkers
  }, [
    activeDatePeriod.value,
    hideActiveDatePeriodMarker,
    hideMarketValue,
    today
  ])

  return (
    <ResponsiveLine
      {...chartConfig}
      layers={[
        'grid',
        areaHighlightLayer,
        'markers',
        'axes',
        'areas',
        'crosshair',
        StyledLines,
        'points',
        'slices',
        'mesh',
        'legends',
        milestoneMarkers
      ]}
      data={dataSetNew}
      markers={markers}
      yScale={{
        type: 'linear',
        stacked: false,
        ticks,
        max: maxDataPointValue
      }}
      theme={{
        tooltip: {
          text: {
            fontFamily: 'GothamPro'
          }
        },
        axis: {
          legend: {
            text: {
              fontFamily: 'GothamPro',
              fontWeight: 700
            }
          },
          ticks: {
            text: {
              fontFamily: 'GothamPro',
              fontWeight: 700
            }
          }
        }
      }}
    />
  )
}

WealthJourneyLine.propTypes = {
  data: PropTypes.object,
  activeDatePeriod: PropTypes.shape({
    value: PropTypes.number
  }).isRequired,
  ticks: PropTypes.number,
  appContext: PropTypes.object,
  entries: PropTypes.array,
  selectedEntry: PropTypes.object,
  onEntrySelected: PropTypes.func,
  yearsInRange: PropTypes.array,
  selectedEntryType: entryTypePropType.isRequired,
  filterEntryTypesOnChart: PropTypes.bool,
  includeMeetings: PropTypes.bool,
  includeMilestones: PropTypes.bool,
  hideMarketValue: PropTypes.bool,
  hideActiveDatePeriodMarker: PropTypes.bool,
  theming: PropTypes.shape({
    chart: PropTypes.shape({
      lineColor: PropTypes.string,
      milestoneColor: PropTypes.string,
      futureMilestoneColor: PropTypes.string,
      meetingColor: PropTypes.string
    })
  })
}

WealthJourneyLine.defaultProps = {
  data: undefined,
  ticks: 8,
  selectedEntry: null,
  onEntrySelected: noop,
  yearsInRange: [],
  filterEntryTypesOnChart: false,
  includeMeetings: false,
  includeMilestones: false,
  hideMarketValue: false,
  hideActiveDatePeriodMarker: false
}

export default WealthJourneyLine
