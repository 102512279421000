import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import FastAvatar from '../../../molecules/FastAvatar'
import FadeIn from '../../../molecules/Transitions/FadeIn'
import { featureProps, schemaProps } from '../commonProps'
import TeamMemberAttribute from './TeamMemberAttribute'
import { mapCommonStyles } from './commonStyles'
import { useTeamMemberFields } from './useTeamMemberFields'
import BioArea from './BioArea'

const useStyles = makeStyles(theme => ({
  featured: mapCommonStyles(theme)({
    display: 'inline-flex',
    flexDirection: 'row',
    borderColor: theme.palette.gray.dark,
    borderRadius: theme.layout.radius.loose,
    gap: theme.layout.gap.g10,
    minWidth: '160px',
    height: '180px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: ({ features }) => features?.accentColor?.startsWith('#') ? features.accentColor : theme.palette.gray.dark,
    // boxShadow: ({ features }) => features?.shadow && features.shadow in theme.layout.shadow ? theme.layout.shadow[features.shadow] : theme.layout.shadow.none,
    '& .__name': {
      fontWeight: theme.typography.weights.light,
      fontSize: theme.typography.fontSizes.xl4,
      whiteSpace: 'nowrap',
      marginTop: theme.layout.margin.m5
    },
    '& .__attribute': {
      fontWeight: theme.typography.weights.light
    },
    '& .__role': {
      fontWeight: theme.typography.weights.light,
      fontStyle: 'italic'
    },
    '& .__table-label': {
      borderRight: `1px solid ${theme.palette.gray.dark}`,
      paddingRight: theme.layout.padding.p5
    },
    '& .__attributes': {
      display: 'flex',
      flex: '1',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: theme.layout.gap.g2,
      width: '100%',
      minWidth: '250px',
      height: '100%',
      padding: `${theme.layout.padding.p20} ${theme.layout.padding.p10}`,
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.layout.radius.loose
    }
  }),
  title: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.layout.gap.g2,
    minWidth: '160px',
    padding: `${theme.layout.padding.p20} ${theme.layout.padding.p10}`
  }
}))

function FeaturedTeamMember ({ teamMember, fieldConfig, features, schema, index }) {
  const classes = useStyles({ features })
  const { teamMember: _teamMember } = useTeamMemberFields(teamMember, fieldConfig, schema)

  return (
    <FadeIn delay={(index || 0) * 0.025} className={classes.featured} style={features?.cardStyles}>
      <BioArea teamMember={_teamMember} schema={schema} features={features} className={classes.title}>
        <FastAvatar
          avatarUrl={_teamMember.avatarUrl}
          abbreviation={_teamMember.abbreviation}
          size='xl'
        />
        <div className='__name'>{_teamMember.name}</div>
        {_teamMember.role ? (<div className='__role'>{_teamMember.role}</div>) : null}
      </BioArea>
      <div className='__attributes'>
        <table>
          <tbody>
            {_teamMember.attributes.filter(f => f.value).map(f => (
              <tr key={f.key}>
                <td className='__table-label'>{f.config?.hideLabel ? '' : f.label}</td>
                <td>
                  <TeamMemberAttribute
                    key={f.key}
                    className='__attribute'
                    value={f.value}
                    type={f.type}
                    config={f.config}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </FadeIn>
  )
}

FeaturedTeamMember.propTypes = {
  teamMember: PropTypes.object.isRequired,
  fieldConfig: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({
    accessor: PropTypes.string.isRequired,
    label: PropTypes.string,
    type: TeamMemberAttribute.propTypes.type
  }), PropTypes.string])),
  features: featureProps,
  schema: schemaProps,
  index: PropTypes.number
}

export default FeaturedTeamMember
