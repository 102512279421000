import PropTypes from 'prop-types'

export const featureProps = PropTypes.shape({
  shadow: PropTypes.oneOf(['none', 'light', 'normal', 'deep']),
  border: PropTypes.oneOf(['none', 'normal', 'thick']),
  accentColor: PropTypes.string,
  cardStyles: PropTypes.object
})

export const schemaProps = PropTypes.shape({
  attributes: PropTypes.arrayOf(PropTypes.shape({
    attributeId: PropTypes.string,
    accessor: PropTypes.string,
    type: PropTypes.string,
    description: PropTypes.string,
    label: PropTypes.string,
    data: PropTypes.object,
    showBio: PropTypes.bool
  }))
})
