import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { usePolicy } from '../../../../hooks/usePolicy'

const WealthJourneyContext = React.createContext({})

export const useWealthJourney = () => {
  return useContext(WealthJourneyContext)
}

const useWealthJourneyMananger = () => {
  // permissions
  const canViewMilestones = usePolicy('wj_view_milestones')
  const canEditMilestones = usePolicy('wj_edit_milestones')
  const canViewActivities = usePolicy('wj_view_activities')
  const canEditActivities = usePolicy('wj_edit_activities')
  const canViewMeetings = usePolicy('wj_view_meetings')
  const canEditMeetings = usePolicy('wj_edit_meetings')

  // selection
  const [selectedEntry, setSelectedEntry] = React.useState(null)

  /* eslint-disable object-property-newline */
  return useMemo(() => {
    return {
      canViewMilestones, canEditMilestones,
      canViewActivities, canEditActivities,
      canViewMeetings, canEditMeetings,
      selectedEntry, setSelectedEntry
    }
  }, [
    canViewMilestones, canEditMilestones,
    canViewActivities, canEditActivities,
    canViewMeetings, canEditMeetings,
    selectedEntry, setSelectedEntry
  ])
  /* eslint-enable object-property-newline */
}

function WealthJourneyProvider ({ children }) {
  const contextValue = useWealthJourneyMananger()

  return (
    <WealthJourneyContext.Provider value={contextValue}>
      {children}
    </WealthJourneyContext.Provider>
  )
}

WealthJourneyProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default WealthJourneyProvider
